.wrapper {
	padding: 10px 16px;
	position: relative;
}
/*.wrapper:hover {*/
/*    background-color: #f8f8f8;*/
/*}*/
.imageWrapper {
	margin-right: 10px;
}
.image {
	width: 50px;
	height: 50px;
	border-radius: 15px;
}
.time {
	margin-left: 10px;
	font-size: 12px;
	color: gray;
}
