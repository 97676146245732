.card {
	background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
}

.title {
	font-family:
		Greycliff CF,
		var(--mantine-font-family);
	font-weight: 700;
}

.item {
	border-radius: var(--mantine-radius-md);
	background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
	transition:
		box-shadow 150ms ease,
		transform 100ms ease;

	@mixin hover {
		box-shadow: var(--mantine-shadow-md);
		transform: scale(1.05);
	}
}

a {
	color: var(--mantine-color-dark);
	text-decoration: none;
}
