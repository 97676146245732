.tab {
	position: relative;
	border: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
	background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));

	border-radius: 5px 5px 0 0;

	&:first-of-type {
		@mixin rtl {
			border-radius: 0 5px 5px 0;
		}
	}

	&:last-of-type {
		@mixin rtl {
			border-radius: 5px 0 0 5px;
		}
	}

	& + & {
		border-left-width: 0;

		@mixin rtl {
			border-right-width: 0;
			border-left-width: 1px;
		}
	}

	@mixin hover {
		background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
	}

	&[data-active] {
		z-index: 1;
		background-color: var(--mantine-color-blue-light);
		border-color: var(--mantine-color-blue-light);
		color: light-dark(var(--mantine-color-dark), var(--mantine-color-white));

		@mixin hover {
			background-color: var(--mantine-color-blue-light-hover);
		}
	}
}

.tab-panel {
	background-color: red;
}
