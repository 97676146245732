.icon {
	width: rem(22px);
	height: rem(22px);
}

.dark {
	@mixin dark {
		display: none;
	}

	@mixin light {
		display: block;
	}
}

.light {
	@mixin light {
		display: none;
	}

	@mixin dark {
		display: block;
	}
}
